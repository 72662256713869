import React from 'react'

export default function OperatorTruckMenu() {
  // TODO:  
  // --> get menu from server
  // --> render menu items 
  // // --> group by 

  return (

    <div className='truck-menu container'>
      <h3>Taco Truck Menu</h3>
      <ul>
        <li className='container'>Chicken Tacos ...... $5.99</li>
        <li className='container'>Barbacoa Tacos ...... $6.99</li>
        <li className='container'>Rice n Beans ....... $2.99</li>
      </ul>
    </div>
  );
}
